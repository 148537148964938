module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8B4PM4TCFV"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/mnt/d/ShapeGanicGatsby"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ShapeGanic - Calorie Counter & Weight Tracker","short_name":"ShapeGanic","start_url":"/","background_color":"#fff","theme_color":"#448aff","display":"standalone","icon":"src/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"6655e7746efa100084eca6af1de0372c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"^.*/blog/.+$"}],"height":4,"color":"#448aff"},
    }]
