import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LegalNotice from "./affiliateMarketing";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = function Layout({
  children
}) {
  return <>{children}</>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <LegalNotice legal={`As an Amazon Associate I earn from qualifying purchases. However, the price remains unchanged for you. I only recommend products that I use myself and have absolutely convinced me. The proceeds will be used for the maintenance of this information platform to continue to create highly informative and independent expert articles for you in the future.`} mdxType="LegalNotice" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      