import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  container,
  screenshot
} from "./appImage.module.css"

const AppImage = imageData => {
  const image = imageData.image
  const imageAlt = imageData.imageAlt

  return (
    <div className={container}>
      <GatsbyImage
        image={image}
        className={screenshot}
        alt={imageAlt} />
    </div>
  )
}

export default AppImage
