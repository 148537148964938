import React from "react"
import { Link } from "gatsby"
import { linkStyle } from "./link.module.css"

const NavLinkGlobal = ({ to, children }) => {
  return (
    <Link className={linkStyle} to={to}>
      {children}
    </Link>
  )
}

export default NavLinkGlobal
