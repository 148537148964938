import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Summary from "./summary"

import { container } from "./summaries.module.css"

const Container = ({ children }) => <div className={container}>{children}</div>

const Summaries = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { type: { eq: "article" }, lang: { eq: "en" } } }
      ) {
        nodes {
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            summary
            main_image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
            main_image_alt
            main_image_credit_text
            main_image_credit_link
          }
          fields {
            readingTime {
              minutes
            }
          }
          id
          slug
        }
      }
    }
  `)

  const summaries = data.allMdx.nodes.map(item => {
    const englishReadingTime = `${Math.floor(item.fields.readingTime.minutes)} min read`
    return <Summary
      id={item.id}
      slug={item.slug}
      title={item.frontmatter.title}
      summary={item.frontmatter.summary}
      date={item.frontmatter.date}
      readingTime={englishReadingTime}
      image={getImage(item.frontmatter.main_image)}
      imageAlt={item.frontmatter.main_image_alt}
      imageAuthor={item.frontmatter.main_image_credit_text}
      imageLink={item.frontmatter.main_image_credit_link}
    />
  })

  return <Container>{summaries}</Container>
}

export default Summaries
