import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../../src/components/blog/legal/affiliateMarketing.mdx";
import BlogOverview from "../../../../src/components/blog/overview/blogOverview.mdx";
import * as React from 'react';
export default {
  graphql,
  getImage,
  Disclosure,
  BlogOverview,
  React
};