import Container from "../../../../src/components/container/container";
import Navigation from "../../../../src/components/navigation/navigation";
import Summaries from "../../../../src/components/blog/summary/summaries";
import SEO from "../../../../src/components/blog/seo/seo";
import * as React from 'react';
export default {
  Container,
  Navigation,
  Summaries,
  SEO,
  React
};