import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import ImageTitle from "./imageTitle"
import {
  overviewTitleContainer,
  overviewContainer,
} from "./blogOverview.module.css"

const BlogOverview = props => {
  const title = props.title
  const data = useStaticQuery(graphql`
    {
      allMdx(
        sort: { fields: frontmatter___date, order: ASC }
        filter: { frontmatter: { lang: { eq: "de" }, type: { eq: "article" } } }
      ) {
        nodes {
          frontmatter {
            title
            main_image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
            main_image_alt
          }
          slug
        }
      }
    }
  `)

  const slugFileNameRegExp = /index\.\w+$/im

  const overviews = data.allMdx.nodes.map(node => {
    const navPath = node.slug.replace(slugFileNameRegExp, "")

    return (
      <ImageTitle
        slug={`../${navPath}`}
        title={node.frontmatter.title}
        image={getImage(node.frontmatter.main_image)}
        imageAlt={node.main_image_alt}
      />
    )
  })

  return (
    <div className={overviewTitleContainer}>
      <h3>{title}</h3>
      <div className={overviewContainer}>{overviews}</div>
    </div>
  )
}

export default BlogOverview
