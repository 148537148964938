import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import {
  imageAttributionContainer,
  imageLinkStyle,
  previewImage,
  attribution,
} from "./imageAttribution.module.css"

const ImageAttribution = imageData => {
  const image = imageData.image
  const imageAlt = imageData.imageAlt
  const imageAuthor = imageData.imageAuthor
  const imageLink = imageData.imageLink
  const imageNavPath = imageData.imageNavPath

  return (
    <div className={imageAttributionContainer}>
      <Link to={imageNavPath}>
        <GatsbyImage image={image} alt={imageAlt} className={previewImage} />
      </Link>
      <div className={attribution}>
        Photo by{" "}
        <OutboundLink href={imageLink} className={imageLinkStyle}>
          {imageAuthor}
        </OutboundLink>
      </div>
    </div>
  )
}

export default ImageAttribution
