import React from "react"
import { Link } from "gatsby"
import ImageAttribution from "./imageAttribution"
import {
  summaryContainer,
  summaryArticle,
  summaryTitle,
  datePosted,
  blogPostLink,
  blog,
} from "./summary.module.css"

const Summary = ({ id, slug, title, date, readingTime, summary, image, imageAlt, imageAuthor, imageLink }) => {
  const navPath = slug

  return (
    <article className={blog} key={id}>
      <ImageAttribution
        image={image}
        imageAlt={imageAlt}
        imageAuthor={imageAuthor}
        imageLink={imageLink}
        imageNavPath={navPath}
      />
      <div className={summaryContainer}>
        <h2 className={summaryTitle}>
          <Link to={navPath} className={blogPostLink}>
            {title}
          </Link>
        </h2>
        <p className={datePosted}>{date} &#8226; {readingTime}</p>
        <p className={summaryArticle}>{summary}</p>
      </div>
    </article>
  )
}

export default Summary
