import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Image from "../image/image"
import NavLinkGlobal from "../link/navLinkGlobal"

export default function AppPromotion() {
  const data = useStaticQuery(graphql`
    {
      appBenefitsImage: file(
        relativePath: { eq: "de/welches-ist-die-beste-kalorienzaehler-app.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      appBenefitsContent: file(relativePath: { eq: "app_promotion.md" }) {
        childMarkdownRemark {
          frontmatter {
            image_app_promotion_alt_de
            image_app_promotion_desc_de
            image_app_promotion_credit_text_de
            image_app_promotion_credit_link_de
          }
        }
      }
    }
  `)

  return (
    <>
      <h2>Abnehmen und Muskelaufbau</h2>
      <p>
        Du liest Artikel wie diesen hier, weil du echte Ziele hast. Du willst etwas
        erreichen, etwas was dir wichtig ist. Du möchtest etwas Gewicht verlieren, um
        dich wohler zu fühlen, gesünder zu sein oder um dir selbst mehr zu gefallen.
        Du möchtest Muskulatur aufbauen, um auch zukünftig eine schlanke und schöne
        Figur zu haben. Supplements, also Nahrungsergänzungsmittel, können hierbei
        sehr hilfreich sein, aber am Ende des Tages entscheiden deine{" "}
        <b>Tagesgesamtkalorien</b> und die <b>Makronährstoffverteilung</b> über{" "}
        <b>Erfolg und Misserfolg!</b>
      </p>
      <Image
        image={getImage(data.appBenefitsImage)}
        imageAlt={data.appBenefitsContent.childMarkdownRemark.frontmatter.image_app_promotion_alt_de}
        imageDesc={data.appBenefitsContent.childMarkdownRemark.frontmatter.image_app_promotion_desc_de}
        imageAuthor={data.appBenefitsContent.childMarkdownRemark.frontmatter.image_app_promotion_credit_text_de}
        imageLink={data.appBenefitsContent.childMarkdownRemark.frontmatter.image_app_promotion_credit_link_de}
      />
      <h3>Gesamtkalorien und Makronährstoffverteilung</h3>
      <p>
        Um auf der Zielgeraden zu bleiben musst du deine Kalorien kontrollieren und im
        Blick behalten. Aber auch die Makronährstoffverteilung, also das Verhältnis
        von gegessenem Eiweiß, Kohlenhydraten und Fetten, ist äußerst wichtig. Diese
        Werte täglich händisch in einer Tabellenkalkulation zu ermitteln ist sehr
        aufwendig und frustrierend. Um dir zu helfen stelle ich dir eine 100%
        kostenlose{" "}
        <NavLinkGlobal to="/de/#appstores">Kalorienzähler App</NavLinkGlobal> zur
        Verfügung. Auch hilft sie dir dein Gewicht zu protokollieren und graphisch
        auszuwerten. Worauf wartest du?{" "}
        <NavLinkGlobal to="/de/#appstores">Starte JETZT durch!</NavLinkGlobal>
      </p>
    </>
  )
}
