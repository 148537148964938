import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../../src/components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../../src/components/blog/layout.de";
import * as React from 'react';
export default {
  graphql,
  getImage,
  BlogOverview,
  Layout,
  React
};