import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import {
  navItem,
  navItemBlog,
  navItemSelected,
} from "./navigation.module.css"

export default function Navigation() {
  const data = useStaticQuery(graphql`
    {
      header: file(relativePath: { eq: "header.md" }) {
        childMarkdownRemark {
          frontmatter {
            navHome_en
            navBlog_en
          }
        }
      }
    }
  `)

  return (
    <nav>
      <Link
        className={navItem}
        to={"/"}
        activeClassName={navItemSelected}
      >
        {data.header.childMarkdownRemark.frontmatter.navHome_en}
      </Link>
      <Link
        className={navItemBlog}
        to={"/blog"}
        activeClassName={navItemBlog}
        partiallyActive={true}
      >
        {data.header.childMarkdownRemark.frontmatter.navBlog_en}
      </Link>
    </nav>
  )
}
