import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { linkStyle, disclosure } from "./affiliateLink.module.css"

const AffiliateLink = ({ url, children }) => {
  return (
    <OutboundLink href={url} className={linkStyle} target="_blank">
      <span className={disclosure}></span>
      {children}
    </OutboundLink>
  )
}

export default AffiliateLink
