import Container from "../../../../src/components/container/container";
import Navigation from "../../../../src/components/navigation/navigation.de";
import Summaries from "../../../../src/components/blog/summary/summaries.de";
import Legal from "../../../../src/components/legal/legal.de";
import SEO from "../../../../src/components/blog/seo/seo";
import * as React from 'react';
export default {
  Container,
  Navigation,
  Summaries,
  Legal,
  SEO,
  React
};