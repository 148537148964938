import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, title, keywords, imageUrl }) {
  const { pathname } = useLocation()

  const { site, logo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            authorSeo
            url
            twitterUsername
          }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
          publicURL
        }
      }
    `
  )

  const rootUrl = site.siteMetadata.url
  const thisUrl = `${rootUrl}${pathname}`

  const defaultImagePath = logo.publicURL
  const insertedImagePath = imageUrl || defaultImagePath

  const insertedImageUrl = `${rootUrl}${insertedImagePath}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `article`,
        },
        {
          property: `og:image`,
          content: insertedImageUrl,
        },
        {
          property: `og:url`,
          content: thisUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: insertedImageUrl,
        },
        {
          name: `image`,
          content: insertedImageUrl,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  title: ``,
  lang: `en`,
  description: ``,
  keywords: ``,
}

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  lang: PropTypes.string,
  keywords: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
