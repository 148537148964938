import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { linkStyle } from "./link.module.css"

const Link = ({ url, children }) => {
  return (
    <OutboundLink className={linkStyle} href={url} target="_blank">
      {children}
    </OutboundLink>
  )
}

export default Link
