import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BlogOverview from "./blogOverview.de";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = function Layout({
  children
}) {
  return <>{children}</>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <BlogOverview title={`Lies gleich den nächsten Artikel...`} mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      