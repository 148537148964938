import React from "react"
import { useLocation } from "@reach/router"
import { titleStyle, datePosted } from "./title.module.css"

const Title = ({ title, date, readingTime }) => {
  const { pathname } = useLocation()
  const isGermanRegExp = /(^\/de\/.*$)|(^\/de\/?$)/im
  const isGerman = isGermanRegExp.test(pathname)
  const localizedTimeLabel = isGerman ? 'Min. Lesedauer' : 'min read'

  return <div>
    <h1 className={titleStyle}>{title}</h1>
    <p className={datePosted}>{date} &#8226; {Math.floor(readingTime)} {localizedTimeLabel}</p>
  </div>
}

export default Title
