import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  imageTitleContainer,
  previewImage,
  blogPostTitle,
} from "./imageTitle.module.css"

const ImageTitle = article => {
  const slug = article.slug
  const title = article.title
  const image = article.image
  const imageAlt = article.imageAlt

  return (
    <div className={imageTitleContainer}>
      <Link to={slug} replace>
        <GatsbyImage image={image} alt={imageAlt} className={previewImage} />
      </Link>
      <h4 className={blogPostTitle}>{title}</h4>
    </div>
  )
}

export default ImageTitle
