import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LegalNotice from "./affiliateMarketing";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = function Layout({
  children
}) {
  return <>{children}</>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <LegalNotice legal={`Als Amazon-Partner verdiene ich an qualifizierten Verkäufen. Der Preis bleibt für dich jedoch unverändert. Ich empfehle ausschließlich Produkte, die ich selbst verwende und mich absolut überzeugt haben. Die Erlöse werden zur Wartung dieser Informationsplattform verwendet, um auch in Zukunft hochinformative und unabhängige Expertenartikel für dich zu erstellen.`} mdxType="LegalNotice" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      