import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import {
  blogPostImage,
  blogPostImageContainer,
  description,
  attribution,
  imageLinkStyle,
  imageAttributionContainer,
} from "./image.module.css"

const Image = imageData => {
  const image = imageData.image
  const imageAlt = imageData.imageAlt
  const imageDesc = imageData.imageDesc
  const imageAuthor = imageData.imageAuthor
  const imageLink = imageData.imageLink

  return (
    <div className={blogPostImageContainer}>
      <div className={imageAttributionContainer}>
        <GatsbyImage image={image} alt={imageAlt} className={blogPostImage} />
        <div className={attribution}>
          Photo by{" "}
          <OutboundLink href={imageLink} className={imageLinkStyle}>
            {imageAuthor}
          </OutboundLink>
        </div>
      </div>
      <p className={description}>{imageDesc}</p>
    </div>
  )
}

export default Image
