// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-calorie-calculator-favorite-food-index-mdx": () => import("./../../../src/pages/blog/calorie-calculator-favorite-food/index.mdx" /* webpackChunkName: "component---src-pages-blog-calorie-calculator-favorite-food-index-mdx" */),
  "component---src-pages-blog-calorie-calculator-serving-size-index-mdx": () => import("./../../../src/pages/blog/calorie-calculator-serving-size/index.mdx" /* webpackChunkName: "component---src-pages-blog-calorie-calculator-serving-size-index-mdx" */),
  "component---src-pages-blog-calorie-calculator-to-lose-weight-index-mdx": () => import("./../../../src/pages/blog/calorie-calculator-to-lose-weight/index.mdx" /* webpackChunkName: "component---src-pages-blog-calorie-calculator-to-lose-weight-index-mdx" */),
  "component---src-pages-blog-calorie-counter-app-export-data-index-mdx": () => import("./../../../src/pages/blog/calorie-counter-app-export-data/index.mdx" /* webpackChunkName: "component---src-pages-blog-calorie-counter-app-export-data-index-mdx" */),
  "component---src-pages-blog-fuer-was-ist-casein-gut-index-de-mdx": () => import("./../../../src/pages/blog/fuer-was-ist-casein-gut/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-fuer-was-ist-casein-gut-index-de-mdx" */),
  "component---src-pages-blog-how-and-when-should-i-take-creatine-index-mdx": () => import("./../../../src/pages/blog/how-and-when-should-i-take-creatine/index.mdx" /* webpackChunkName: "component---src-pages-blog-how-and-when-should-i-take-creatine-index-mdx" */),
  "component---src-pages-blog-how-do-i-calculate-my-calorie-needs-index-mdx": () => import("./../../../src/pages/blog/how-do-i-calculate-my-calorie-needs/index.mdx" /* webpackChunkName: "component---src-pages-blog-how-do-i-calculate-my-calorie-needs-index-mdx" */),
  "component---src-pages-blog-index-de-mdx": () => import("./../../../src/pages/blog/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-index-de-mdx" */),
  "component---src-pages-blog-index-mdx": () => import("./../../../src/pages/blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-index-mdx" */),
  "component---src-pages-blog-kalorienrechner-lebensmittel-favoriten-index-de-mdx": () => import("./../../../src/pages/blog/kalorienrechner-lebensmittel-favoriten/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-kalorienrechner-lebensmittel-favoriten-index-de-mdx" */),
  "component---src-pages-blog-kalorienzaehler-app-exportieren-kostenlos-index-de-mdx": () => import("./../../../src/pages/blog/kalorienzaehler-app-exportieren-kostenlos/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-kalorienzaehler-app-exportieren-kostenlos-index-de-mdx" */),
  "component---src-pages-blog-kalorienzaehler-app-kostenlos-index-de-mdx": () => import("./../../../src/pages/blog/kalorienzaehler-app-kostenlos/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-kalorienzaehler-app-kostenlos-index-de-mdx" */),
  "component---src-pages-blog-kalorienzaehler-kalorien-je-portion-berechnen-index-de-mdx": () => import("./../../../src/pages/blog/kalorienzaehler-kalorien-je-portion-berechnen/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-kalorienzaehler-kalorien-je-portion-berechnen-index-de-mdx" */),
  "component---src-pages-blog-was-bedeutet-whey-protein-index-de-mdx": () => import("./../../../src/pages/blog/was-bedeutet-whey-protein/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-was-bedeutet-whey-protein-index-de-mdx" */),
  "component---src-pages-blog-was-hilft-wirklich-fett-zu-verlieren-index-de-mdx": () => import("./../../../src/pages/blog/was-hilft-wirklich-fett-zu-verlieren/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-was-hilft-wirklich-fett-zu-verlieren-index-de-mdx" */),
  "component---src-pages-blog-was-ist-der-unterschied-zwischen-whey-und-casein-index-de-mdx": () => import("./../../../src/pages/blog/was-ist-der-unterschied-zwischen-whey-und-casein/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-was-ist-der-unterschied-zwischen-whey-und-casein-index-de-mdx" */),
  "component---src-pages-blog-weight-loss-inspiration-index-mdx": () => import("./../../../src/pages/blog/weight-loss-inspiration/index.mdx" /* webpackChunkName: "component---src-pages-blog-weight-loss-inspiration-index-mdx" */),
  "component---src-pages-blog-welche-app-ist-kompatibel-mit-fitbit-index-de-mdx": () => import("./../../../src/pages/blog/welche-app-ist-kompatibel-mit-fitbit/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-welche-app-ist-kompatibel-mit-fitbit-index-de-mdx" */),
  "component---src-pages-blog-what-is-micellar-casein-index-mdx": () => import("./../../../src/pages/blog/what-is-micellar-casein/index.mdx" /* webpackChunkName: "component---src-pages-blog-what-is-micellar-casein-index-mdx" */),
  "component---src-pages-blog-what-is-the-quickest-way-to-lose-belly-fat-index-mdx": () => import("./../../../src/pages/blog/what-is-the-quickest-way-to-lose-belly-fat/index.mdx" /* webpackChunkName: "component---src-pages-blog-what-is-the-quickest-way-to-lose-belly-fat-index-mdx" */),
  "component---src-pages-blog-what-is-whey-protein-exactly-index-mdx": () => import("./../../../src/pages/blog/what-is-whey-protein-exactly/index.mdx" /* webpackChunkName: "component---src-pages-blog-what-is-whey-protein-exactly-index-mdx" */),
  "component---src-pages-blog-what-nutrition-apps-work-with-fitbit-index-mdx": () => import("./../../../src/pages/blog/what-nutrition-apps-work-with-fitbit/index.mdx" /* webpackChunkName: "component---src-pages-blog-what-nutrition-apps-work-with-fitbit-index-mdx" */),
  "component---src-pages-blog-which-is-better-casein-or-whey-index-mdx": () => import("./../../../src/pages/blog/which-is-better-casein-or-whey/index.mdx" /* webpackChunkName: "component---src-pages-blog-which-is-better-casein-or-whey-index-mdx" */),
  "component---src-pages-blog-wie-berechne-ich-meinen-kcal-bedarf-index-de-mdx": () => import("./../../../src/pages/blog/wie-berechne-ich-meinen-kcal-bedarf/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-wie-berechne-ich-meinen-kcal-bedarf-index-de-mdx" */),
  "component---src-pages-blog-wie-nehme-ich-creatin-richtig-ein-index-de-mdx": () => import("./../../../src/pages/blog/wie-nehme-ich-creatin-richtig-ein/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-wie-nehme-ich-creatin-richtig-ein-index-de-mdx" */),
  "component---src-pages-blog-wie-verliert-man-am-schnellsten-fett-am-bauch-index-de-mdx": () => import("./../../../src/pages/blog/wie-verliert-man-am-schnellsten-fett-am-bauch/index.de.mdx" /* webpackChunkName: "component---src-pages-blog-wie-verliert-man-am-schnellsten-fett-am-bauch-index-de-mdx" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-datenschutz-de-mdx": () => import("./../../../src/pages/legal/datenschutz.de.mdx" /* webpackChunkName: "component---src-pages-legal-datenschutz-de-mdx" */),
  "component---src-pages-legal-impressum-de-mdx": () => import("./../../../src/pages/legal/impressum.de.mdx" /* webpackChunkName: "component---src-pages-legal-impressum-de-mdx" */)
}

