import React from "react"
import { Link } from "gatsby"
import {
  navContainer,
  navItem,
} from "./legal.module.css"

export default function Legal() {
  return (
    <nav className={navContainer}>
      <Link className={navItem} to={"/de/legal/impressum"}>Impressum</Link>
      <Link className={navItem} to={"/de/legal/datenschutz"}>Datenschutz</Link>
    </nav>
  )
}
